<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Tienda</h4>
                  <h1>Categorías</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <ion-buttons class="options-nav">
                    <ion-button @click="confirmDeleteAllCategories($event)" v-if="selectedCategories.length">
                      <ion-icon :icon="trashOutline" color="danger" slot="start" ></ion-icon>
                      <ion-label color="dark">Categorías</ion-label>
                    </ion-button>
                    <ion-button @click="confirmDeleteAllSubcategories($event)" v-if="selectedSubcategories.length">
                      <ion-icon :icon="trashOutline" color="danger" slot="start" ></ion-icon>
                      <ion-label color="dark">Subcategorías</ion-label>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                    <ion-button @click="exportCSV($event)">
                      <ion-icon :icon="cloudDownloadOutline" slot="icon-only" ></ion-icon>
                    </ion-button>
                    <ion-button @click="$router.push({ path: `/modules/shop/categories/new` })">
                      <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile" v-if="elements">
            <DataTable 
                :value="elements.filter(x => x.type === 'category')"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedCategories"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name', 'first', 'second', 'desserts', 'drinks', 'uid']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
                @rowReorder="reorderCategories"
                v-model:expandedRows="expandedRows"
                @rowExpand="onRowExpand"
                @rowCollapse="onRowCollapse"
                >
                <template #empty>
                    No hay resultados
                </template>
                <Column selectionMode="multiple" style="width: 64px"></Column>

                <Column field="images" header="Imagen" style="width: 96px;">
                  <template #body="slotProps">
                      <img v-if="slotProps.data.images.length" :src="slotProps.data.images[0]['256']" alt="preview" class="preview" />
                  </template>
                </Column>

                <Column :rowReorder="true" :reorderableColumn="false" style="width: 64px; cursor: move;" />

                <Column :expander="true" style="width: 64px" />

                <Column field="name" header="Nombre" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.name" :key="slotProps.data.id">{{ slotProps.data.name }}</span>
                  </template>
                </Column>

                <Column field="description" header="Descripción">
                  <template #body="slotProps">
                      <span class="table-field" v-if="slotProps.data.description">{{ slotProps.data.description.toLowerCase() }}</span>
                  </template>
                </Column>


                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" :router-link="'/modules/shop/categories/' + slotProps.data.objectID">
                        <ion-label>Editar</ion-label>
                      </ion-button>
                  </template>
                </Column>

                <template #expansion="slotProps">
                    <div class="options-subtable">
                        <DataTable :value="elements.filter(x => { if(x.father) {return x.father.name === slotProps.data.name} else {return false} })" 
                            @rowReorder="reorderSubcategories" class="subcategorias"
                            v-model:selection="selectedSubcategories"
                            responsiveLayout="scroll">
                            <template #empty>
                                No hay Subcategorías
                            </template>

                            <Column selectionMode="multiple" style="width: 64px" :exportable="false"></Column>

                            <Column field="images" header="Imagen" style="width: 96px;">
                              <template #body="slotProps">
                                  <img v-if="slotProps.data.images.length" :src="slotProps.data.images[0]['256']" alt="preview" class="preview" />
                              </template>
                            </Column>
                            <Column :rowReorder="true" :reorderableColumn="false" style="width: 64px; cursor: move;" />

                            <Column field="name" header="Nombre" filterMatchMode="contains">
                              <template #body="slotProps">
                                  <span v-if="slotProps.data.name" :key="slotProps.data.id">{{ slotProps.data.name }}</span>
                              </template>
                            </Column>

                          <Column field="description" header="Descripción">
                            <template #body="slotProps">
                                <span class="table-field" v-if="slotProps.data.description">{{ slotProps.data.description.toLowerCase() }}</span>
                            </template>
                          </Column>


                            <!-- Acciones -->
                            <Column field="actions" style="width: 122px;">
                              <template #body="slotProps">
                                  <ion-button shape="round" fill="outline" color="dark" :router-link="'/modules/shop/categories/' + slotProps.data.objectID">
                                    <ion-label>Editar</ion-label>
                                  </ion-button>
                              </template>
                            </Column>
                        </DataTable>
                    </div>
                </template>
            </DataTable>
        </section>
      </div>

      <Footer></Footer>

    </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonLabel } from '@ionic/vue';
import { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline } from 'ionicons/icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmPopup from 'primevue/confirmpopup';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'ShopCategories',
  components: {
    IonContent,
    IonPage,
    Header,

    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
    Footer,
    DataTable,
    Column,
    ConfirmPopup,
    InputText,
    IonLabel
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: {},

        activeOptions: [
          {
            label: 'Activo',
            value: true,
          },
          {
            label: 'Inactivo',
            value: false,
          },
        ],
        selectedCategories: [],
        selectedSubcategories: [],
        expandedRows: []
      };
    },
    computed: {
      ...mapState('shop', {
        elements: state => state.productsCategories,
      }),

      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('shop',['getProductsCategories', 'deleteProductCategory', 'updateProductCategory']),

      initFilters() {
          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },

      confirmDeleteAllCategories(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar estas categorías y sus subcategorías?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.selectedCategories.forEach(category => {
                      const hijos = this.categories.filter(x => { if(x.father) {return x.father.id === category.id} else {return false} });
                      hijos.forEach(element => {
                        this.deleteProductCategory(element.id);
                      });
                      this.deleteProductCategory(category.id);
                    });

                    this.selectedCategories = [];
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },

      confirmDeleteAllSubcategories(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar estas subcategorías?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.selectedSubcategories.forEach(category => {
                      this.deleteProductCategory(category.id);
                    });
                    this.selectedSubcategories = [];
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },


      reorderCategories(event){
        event.value.forEach((element, index) => {
          element.order = index;
          this.deleteProductCategory({data:{order: element.order}, id: element.id})
        });
      },
      reorderSubcategories(event){
        event.value.forEach((element, index) => {
          element.order = index;
          this.deleteProductCategory({data:{order: element.order}, id: element.id})
        });
      },


      handleEdit(id){
        this.$router.push({ path: `/modules/shop/categories/${id}` })
      },
      confirmDeleteCategory(event, id){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar esta categoría y sus subcategorias?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    const hijos = this.categories.filter(x => { if(x.father) {return x.father.id === id} else {return false} });
                    hijos.forEach(element => {
                      this.deleteProductCategory(element.id);
                    });
                    this.deleteProductCategory(id);
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },
      confirmDeleteSubcategory(event, id){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar esta subcategoría?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                     this.deleteProductCategory(id);
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },
      segmentChanged(ev) {
        this.$router.push({ path: `/modules/shop/${ev.detail.value}` })
      }
    },
    created(){
        this.getProductsCategories();
        this.initFilters();
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline };
    }
});
</script>

<style scoped>
  .preview{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }

</style>